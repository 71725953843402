import React from "react";

export default function Loader() {
  return (
    <section className="flex items-center justify-center p-8">
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin ease-linear rounded-full w-32 h-32 border-t-8 border-b-8 border-yellow-950"></div>
        <div className="animate-spin ease-linear rounded-full w-32 h-32 border-t-8 border-b-8 border-red-500 ml-3"></div>
        <div className="animate-spin ease-linear rounded-full w-32 h-32 border-t-8 border-b-8  border-sky-500 ml-3"></div>
      </div>
    </section>
  );
}
