import React, { useState } from "react";
import Loader from "./Loader";

export default function ContactUs() {
  const [isLoad, setIsLoad] = useState(true);
  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    note: "",
  });

  const resetForm = () => {
    setFormData({
      subject: "",
      email: "",
      note: "",
    });
  };

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://contactus.htmlwebgames.com/api/contact-us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, site_name: "growthward.online" }),
        }
      );
      if (response.ok) {
        alert("Your message has been sent successfully!");
        resetForm();
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <h1 className="font-extrabold text-yellow-900 text-center text-2xl m-3">
        Contact Us
      </h1>
      <div className="md:mx-auto mx-3 my-5 p-4 bg-yellow-400 rounded-3xl shadow sm:p-6 md:p-8 text-yellow-900 max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="subject" className="block mb-2 text-sm font-bold ">
              Your subject :
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              className=" border border-transparent text-sm rounded-lg focus-visible:outline-none focus:outline-none focus-visible:ring-0 block w-full p-2.5 "
              placeholder="Enter Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-bold">
              Your email :
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="border border-transparent text-sm rounded-lg focus-visible:outline-none focus:outline-none focus-visible:ring-0 block w-full p-2.5 "
              placeholder="name@company.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label htmlFor="note" className="block mb-2 text-sm font-bold">
              Your note :
            </label>
            <textarea
              id="note"
              name="note"
              rows="4"
              className="border border-transparent text-sm rounded-lg focus-visible:outline-none focus:outline-none focus-visible:ring-0 block w-full p-2.5 "
              placeholder="Write your thoughts here..."
              value={formData.note}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="w-52 border-2 border-yellow-400 bg-yellow-600 hover:text-white hover:bg-yellow-800 focus:ring-0 focus:outline-none focus:ring-slate-300 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
