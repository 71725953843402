import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 text-yellow-900 rounded-lg shadow-2xl m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>growthward.online!</b>
        </p>
        <p className="my-3">
          At growthward.online, we are dedicated to helping individuals and
          businesses achieve their growth potential through innovative solutions
          and expert guidance. Our mission is to provide the tools, resources,
          and support needed to foster development and success.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, growthward.online was established to offer
          comprehensive growth strategies and insights to a global audience. We
          believe in the power of knowledge and continuous improvement to drive
          success.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team comprises experienced professionals from various fields,
          including business development, marketing, and technology. We are
          passionate about growth and committed to helping our clients reach
          their full potential.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Consulting:</b> Expert advice and strategies tailored to your
            unique needs.
          </li>
          <li className="my-2">
            <b>Training Programs:</b> Educational resources and workshops to
            enhance skills and knowledge.
          </li>
          <li className="my-2">
            <b>Resource Hub:</b> A collection of tools and materials to support
            your growth journey.
          </li>
        </ul>

        <h6 className="font-bold mt-3">Get In Touch</h6>
        <p className="my-2">
          Thank you for choosing growthward.online as your partner in growth. We
          are excited to support your journey to success. If you have any
          questions or feedback, feel free to reach out to us at
          contact@growthward.online.
        </p>
      </section>
    </>
  );
}
