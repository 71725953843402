import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="relative group duration-500 cursor-pointer group overflow-hidden text-gray-50 h-72 w-56 rounded-2xl hover:duration-700 ">
      <div className="w-56 h-72  text-gray-800">
        <div className="flex flex-row justify-between">
          <img
            src={data.logo}
            alt={data.title}
            className="w-full h-64 object-cover hover:scale-105"
          />
        </div>
      </div>
      <div className="absolute bg-yellow-100 -bottom-14 w-56 p-2 flex flex-col gap-1 group-hover:-bottom-0 group-hover:duration-600 duration-500">
        <p className="text-lime-800 font-bold text-lg text-center">
          {data.title}
        </p>
        <p className="text-neutral-800 text-center">
          <Link to={`/single?slug=${data.slug}`}>
            <button className="text-white bg-gradient-to-r from-yellow-500 to-yellow-700 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200  font-bold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">
              Play
            </button>
          </Link>
        </p>
      </div>
    </div>
  );
}
