import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="text-black text-center text-md bg-footer-image">
      <p className="mt-10">
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            growthward.online
          </Link>
        </span>
      </p>
    </footer>
  );
}
